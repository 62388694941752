@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon/icomoon.eot?srf3rx");
  src: url("../../fonts/icomoon/icomoon.eot?srf3rx#iefix") format("embedded-opentype"), url("../../fonts/icomoon/icomoon.ttf?srf3rx") format("truetype"), url("../../fonts/icomoon/icomoon.woff?srf3rx") format("woff"), url("../../fonts/icomoon/icomoon.svg?srf3rx#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* =======================================================
*
* 	Template Style 
*
* ======================================================= */
body {
  font-family: "Poppins", Arial, sans-serif;
  font-weight: 300;
  font-size: 16px;
  line-height: 2;
  color: rgb(127.5, 127.5, 127.5);
  background: rgb(244.8, 244.8, 244.8);
}

#page {
  position: relative;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.offcanvas #page {
  overflow: hidden;
  position: absolute;
}
.offcanvas #page:after {
  -moz-transition: all 2s ease;
  -o-transition: all 2s ease;
  -webkit-transition: all 2s ease;
  -ms-transition: all 2s ease;
  transition: all 2s ease;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 101;
  background: rgba(0, 0, 0, 0.7);
  content: "";
}

a {
  color: #4aca85;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
a:hover, a:active, a:focus {
  color: #4aca85;
  outline: none;
  text-decoration: none;
}

p {
  margin-bottom: 20px;
}

h1, h2, h3, h4, h5, h6, figure {
  color: #303133;
  font-family: "Nunito", Arial, sans-serif;
  font-weight: 400;
  margin: 0 0 20px 0;
  line-height: 1.3;
}

::-webkit-selection {
  color: #fff;
  background: #4aca85;
}

::-moz-selection {
  color: #fff;
  background: #4aca85;
}

::selection {
  color: #fff;
  background: #4aca85;
}

.product-img,
.work-img,
.author-img,
.mobile-img,
.colorlib-video,
.blog-img,
.colorlib-about-img,
.services-img,
.staff-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}

.colorlib-nav {
  margin: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9;
  margin: 0 auto;
}
@media screen and (max-width: 768px) {
  .colorlib-nav {
    margin: 0;
  }
}
.colorlib-nav .top-menu {
  padding: 30px 30px;
}
@media screen and (max-width: 768px) {
  .colorlib-nav .top-menu {
    padding: 28px 1em;
  }
}
.colorlib-nav #colorlib-logo {
  font-size: 20px;
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-weight: 700;
}
.colorlib-nav #colorlib-logo a {
  position: relative;
  color: #fff;
}
.colorlib-nav #colorlib-logo a i {
  color: #fff;
  display: block;
  font-size: 60px;
  font-weight: normal;
  margin-bottom: 10px;
}
@media screen and (max-width: 768px) {
  .colorlib-nav .menu-1 {
    display: none;
  }
}
.colorlib-nav ul {
  padding: 0;
  margin: 0;
}
.colorlib-nav ul li {
  padding: 8px 0;
  margin: 0;
  list-style: none;
  display: inline;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 2px;
}
.colorlib-nav ul li a {
  position: relative;
  padding: 20px 12px;
  color: rgba(255, 255, 255, 0.9);
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.colorlib-nav ul li a:hover {
  color: #fff;
}
.colorlib-nav ul li.has-dropdown {
  position: relative;
}
.colorlib-nav ul li.has-dropdown .dropdown {
  width: 140px;
  -webkit-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 33px -9px rgba(0, 0, 0, 0.75);
  z-index: 1002;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 40px;
  left: 0;
  text-align: left;
  background: #000;
  padding: 20px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
  -moz-transition: all 0s ease;
  -o-transition: all 0s ease;
  -webkit-transition: all 0s ease;
  -ms-transition: all 0s ease;
  transition: all 0s ease;
}
.colorlib-nav ul li.has-dropdown .dropdown:before {
  bottom: 100%;
  left: 40px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #000;
  border-width: 8px;
  margin-left: -8px;
}
.colorlib-nav ul li.has-dropdown .dropdown li {
  display: block;
  margin-bottom: 0px;
}
.colorlib-nav ul li.has-dropdown .dropdown li:last-child {
  margin-bottom: 0;
}
.colorlib-nav ul li.has-dropdown .dropdown li a {
  padding: 2px 0;
  display: block;
  color: #999999;
  line-height: 1.2;
  text-transform: none;
  font-size: 13px;
  letter-spacing: 0;
}
.colorlib-nav ul li.has-dropdown .dropdown li a:hover {
  color: #fff;
}
.colorlib-nav ul li.btn-cta a {
  color: #fff;
  background: #4aca85;
  padding: 10px 15px;
  -webkit-border-radius: 100px;
  -moz-border-radius: 100px;
  -ms-border-radius: 100px;
  border-radius: 100px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.colorlib-nav ul li.btn-cta a:hover {
  -webkit-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -ms-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 20px -9px rgba(0, 0, 0, 0.75);
}
.colorlib-nav ul li.active > a {
  color: rgba(255, 255, 255, 0.5);
  position: relative;
}
.colorlib-nav ul li.active > a:after {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.colorlib-video {
  overflow: hidden;
  position: relative;
  height: 400px;
}
.colorlib-video a {
  z-index: 1001;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -45px;
  margin-left: -45px;
  width: 90px;
  height: 90px;
  display: table;
  text-align: center;
  background: #798eea;
  -webkit-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -ms-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -o-box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 14px 30px -15px rgba(0, 0, 0, 0.75);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.colorlib-video a i {
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  font-size: 40px;
  color: #fff;
}
.colorlib-video .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.colorlib-video:hover .overlay {
  background: rgba(0, 0, 0, 0.7);
}
.colorlib-video:hover a {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.video-hero {
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  z-index: 0;
}
@media screen and (max-width: 768px) {
  .video-hero {
    heifght: inherit;
    padding: 3em 0;
  }
}
.video-hero .overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.95;
  background: #499bea;
  background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #499bea), color-stop(100%, #798eea));
  background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: linear-gradient(135deg, #499bea 0%, #798eea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#499bea", endColorstr="#798eea", GradientType=1 );
  z-index: 1;
}
.video-hero .display-t {
  display: table;
  height: 700px;
  width: 100%;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .video-hero .display-t {
    height: 600px;
  }
}
.video-hero .display-t.display-t2 {
  height: 500px;
}
.video-hero .display-tc {
  display: table-cell;
  vertical-align: middle;
}
.video-hero .display-tc .holder {
  color: #fff;
  font-family: "Poppins", Arial, sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 7px;
}
.video-hero .display-tc .holder span {
  position: relative;
}
.video-hero .display-tc h2 {
  color: #fff;
  font-size: 60px;
  margin-bottom: 20px;
  font-weight: 300;
}
@media screen and (max-width: 768px) {
  .video-hero .display-tc h2 {
    font-size: 34px;
  }
}
.video-hero .display-tc p {
  font-family: "Nunito", Arial, sans-serif;
  color: #fff;
  font-weight: 200;
  font-size: 30px;
}
.video-hero .display-tc p.breadcrumbs {
  font-size: 14px;
}
.video-hero .display-tc p.breadcrumbs span {
  padding-right: 10px;
  color: rgba(255, 255, 255, 0.5);
}
.video-hero .display-tc p.breadcrumbs span a {
  color: rgba(255, 255, 255, 0.9);
}
.video-hero .display-tc .btn-custom {
  font-size: 16px;
}

.colorlib-about,
.colorlib-shop,
.colorlib-work,
.colorlib-work-featured,
.colorlib-intro,
#colorlib-subscribe,
.colorlib-pricing,
.colorlib-services,
#colorlib-contact,
.colorlib-blog {
  padding: 7em 0;
  clear: both;
}

.colorlib-bg-white {
  background: #fff;
  border: 1px solid rgb(229.5, 229.5, 229.5);
  border-left: none;
  border-right: none;
}

.colorlib-featured {
  padding-bottom: 3em;
  width: 100%;
}
.colorlib-featured .featured-wrap {
  margin-top: -7em;
}
.colorlib-featured .featured-entry img {
  -webkit-box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 3px 19px -9px rgba(0, 0, 0, 0.75);
  margin-bottom: 20px;
}
.colorlib-featured .owl-theme .owl-dots .owl-dot span {
  background: rgb(229.5, 229.5, 229.5);
}
.colorlib-featured .owl-theme .owl-dots .owl-dot.active span {
  background: #cccccc;
}

.services {
  margin-bottom: 50px;
  position: relative;
  z-index: 0;
  padding: 0 1em;
}
@media screen and (max-width: 768px) {
  .services {
    margin-bottom: 3em;
  }
}
.services .icon {
  position: relative;
  margin-bottom: 30px;
  display: inline-block;
}
.services .icon i {
  font-size: 50px;
  color: #798eea;
  z-index: 1;
}
.services .desc h3 {
  font-size: 26px;
  font-weight: 300;
}

.colorlib-intro .colorlib-heading {
  margin-bottom: 2em;
}
.colorlib-intro .play {
  width: 100%;
  display: block;
  margin: 30px 0;
}
.colorlib-intro .play a {
  width: 100px;
  height: 100px;
  display: table;
  margin: 0 auto;
  text-align: center;
  background: #798eea;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.colorlib-intro .play a i {
  font-size: 20px;
  color: #fff;
  display: table-cell;
  vertical-align: middle;
}

.pulse {
  display: table;
  width: 100px;
  height: 100px;
  background: #798eea;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(121, 142, 234, 0.4);
  animation: pulse 2s infinite;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.pulse i {
  font-size: 30px !important;
  display: table-cell;
  vertical-align: middle;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
    box-shadow: 0 0 0 0 rgba(121, 142, 234, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
    box-shadow: 0 0 0 30px rgba(121, 142, 234, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
    box-shadow: 0 0 0 0 rgba(121, 142, 234, 0);
  }
}
.colorlib-work-featured .mobile-wrap {
  margin-bottom: 4em;
  display: block;
}
.colorlib-work-featured .mobile-wrap .mobile-img {
  background-position: top;
  height: 730px;
  -webkit-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.colorlib-work-featured .mobile-wrap .desc {
  padding: 30px;
}
.colorlib-work-featured .mobile-wrap .desc h2 {
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 40px;
}
@media screen and (max-width: 768px) {
  .colorlib-work-featured .mobile-wrap .desc h2 {
    font-size: 34px;
  }
}
.colorlib-work-featured .mobile-wrap .desc .features {
  position: relative;
  margin-bottom: 50px;
}
.colorlib-work-featured .mobile-wrap .desc .features .icon {
  position: absolute;
  top: 10px;
  left: 0;
}
.colorlib-work-featured .mobile-wrap .desc .features .icon i {
  font-size: 30px;
  color: #499bea;
}
.colorlib-work-featured .mobile-wrap .desc .features .f-desc {
  padding-left: 50px;
}

.work-flex {
  width: 100%;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  margin-bottom: 40px;
}
.work-flex .half {
  display: block;
  width: 50%;
  height: 450px;
}
.work-flex .half .display-t {
  height: 450px;
  display: table;
}
@media screen and (max-width: 480px) {
  .work-flex .half .display-t {
    display: block;
    height: auto;
  }
}
.work-flex .half .display-tc {
  display: table-cell;
  vertical-align: middle;
}
.work-flex .half .desc {
  padding: 0 3em;
  background: #fff;
}
@media screen and (max-width: 768px) {
  .work-flex .half .desc {
    padding: 3em;
  }
}
.work-flex .half .desc h2 {
  font-weight: 400;
}
.work-flex .half .desc h2 a {
  color: #303133;
}
@media screen and (max-width: 480px) {
  .work-flex .half {
    width: 100%;
  }
}
.work-flex .work-img {
  display: block;
  height: 450px;
}

.work-grid {
  margin-bottom: 40px;
}
.work-grid .work-img {
  display: block;
  height: 270px;
  position: relative;
}
.work-grid .work-img .icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 270px;
  content: "";
  display: table;
  text-align: center;
  background: #4aca85;
  opacity: 0;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.work-grid .work-img .icon i {
  display: table-cell;
  vertical-align: middle;
  color: #fff;
  font-size: 20px;
}
.work-grid .work-img:hover .icon, .work-grid .work-img:focus .icon {
  opacity: 1;
}
.work-grid .desc {
  background: #fff;
  padding: 25px;
}
.work-grid .desc h2 {
  font-size: 24px;
}
.work-grid .desc h2 a {
  color: #303133;
}
.work-grid .desc h2 a:hover {
  color: #4aca85;
}

.product-entry {
  margin-bottom: 40px;
}
.product-entry .product-img {
  display: block;
  height: 350px;
  margin-bottom: 20px;
  position: relative;
}
.product-entry .product-img .tag {
  position: absolute;
  top: 10px;
  left: 10px;
}
.product-entry .product-img .tag .new {
  font-size: 11px;
  background: #4aca85;
  color: #fff;
  padding: 0.3em 0.5em;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
.product-entry .product-img .tag .sale {
  font-size: 11px;
  background: #798eea;
  color: #fff;
  padding: 0.3em 0.5em;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
.product-entry .product-img .cart {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  display: block;
  padding: 0 1em;
  opacity: 0;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.product-entry .product-img .cart p {
  display: block;
  margin-bottom: 0;
  width: 100%;
  text-transform: uppercase;
  font-size: 12px;
}
.product-entry .product-img .cart p span {
  display: inline-block;
}
.product-entry .product-img .cart p span a {
  display: block;
  color: #fff;
  padding: 1em;
}
.product-entry .product-img .cart p span a:hover {
  background: #4aca85;
}
.product-entry .desc h3 {
  font-size: 20px;
  text-transform: uppercase;
  font-size: 14px;
  margin-bottom: 10px;
}
.product-entry .desc h3 a {
  color: #000;
}
.product-entry .desc .price span {
  color: #4aca85;
  font-weight: 400;
}
.product-entry .desc .price .sale {
  color: rgb(127.5, 127.5, 127.5);
  text-decoration: line-through;
  font-weight: 300;
}
.product-entry:hover .product-img .cart {
  opacity: 1;
}

.staff-entry {
  display: block;
  margin-bottom: 20px;
  z-index: 0;
  background: #fff;
  padding: 30px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.staff-entry .staff-img {
  width: 150px;
  height: 150px;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.staff-entry .desc h3 {
  font-size: 24px;
  margin-bottom: 10px;
}
.staff-entry .desc span {
  display: block;
  margin-bottom: 20px;
  font-size: 13px;
}
.staff-entry .colorlib-social-icons li {
  margin-left: 0;
}
.staff-entry .colorlib-social-icons li a {
  padding: 0;
}

#colorlib-counter {
  position: relative;
  z-index: -1;
  padding-top: 7em;
  padding-bottom: 7em;
}
#colorlib-counter .colorlib-heading {
  margin-bottom: 30px;
}
#colorlib-counter .colorlib-heading h2 {
  color: #fff;
}
#colorlib-counter .colorlib-heading p {
  font-size: 22px;
  color: rgba(255, 255, 255, 0.8);
}

.counter-entry {
  padding: 0;
  position: relative;
}
@media screen and (max-width: 768px) {
  .counter-entry {
    margin-bottom: 20px;
  }
}

.colorlib-counters {
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
.colorlib-counters .overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #499bea;
  background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #499bea), color-stop(100%, #798eea));
  background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: linear-gradient(135deg, #499bea 0%, #798eea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#499bea", endColorstr="#798eea", GradientType=1 );
  opacity: 0.96;
}
.colorlib-counters .icon {
  margin-bottom: 20px;
  width: 100px;
  z-index: 0;
}
.colorlib-counters .icon i {
  font-size: 50px;
  color: rgba(255, 255, 255, 0.9);
  z-index: 1;
}
.colorlib-counters .colorlib-counter {
  font-size: 75px;
  display: block;
  color: #fff;
  font-family: "Nunito", Arial, sans-serif;
  width: 100%;
  font-weight: 300;
  margin-bottom: 0;
}
.colorlib-counters .colorlib-counter-label {
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  display: block;
  font-weight: 300;
}

article {
  background: #fff;
  padding: 35px;
  margin-bottom: 40px;
  -webkit-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
article h2 {
  font-weight: 400;
  margin-bottom: 15px;
}
article h2 a {
  color: #303133;
}
article .admin span {
  font-size: 12px;
  color: #303133;
}
article p.author-wrap {
  position: relative;
  margin-top: 30px;
}
article p.author-wrap .author-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 30px;
  height: 30px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
article p.author-wrap .author {
  padding-left: 40px;
  color: #303133;
  font-size: 12px;
}

.pricing {
  background: #fff;
  padding: 2em 1em 1em 1em;
  -webkit-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 3px 38px -13px rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  border-radius: 4px;
}
.pricing .pricing-heading {
  margin-bottom: 30px;
}
.pricing .price {
  font-size: 72px;
  color: #4aca85;
  line-height: 50px;
  font-weight: 300;
}
.pricing .price .currency {
  font-size: 30px;
  top: -0.9em;
  padding-right: 10px;
}
.pricing .price small {
  font-size: 13px;
  display: block;
  color: #888f94;
}

#colorlib-subscribe {
  position: relative;
}

.colorlib-subscribe {
  clear: both;
  background-size: cover;
  background-attachment: fixed;
  background-position: center center;
}
.colorlib-subscribe .overlay {
  z-index: 0;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: #499bea;
  background: -moz-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -webkit-gradient(left top, right bottom, color-stop(0%, #499bea), color-stop(100%, #798eea));
  background: -webkit-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -o-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: -ms-linear-gradient(-45deg, #499bea 0%, #798eea 100%);
  background: linear-gradient(135deg, #499bea 0%, #798eea 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#499bea", endColorstr="#798eea", GradientType=1 );
  opacity: 0.96;
}
.colorlib-subscribe .colorlib-heading {
  margin-bottom: 30px;
}
.colorlib-subscribe .colorlib-heading h2 {
  color: #fff;
}
.colorlib-subscribe .colorlib-heading p {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.7);
}

.qbstp-header-subscribe {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  width: 100%;
  padding: 0;
}
.qbstp-header-subscribe .col-three-forth {
  width: 70%;
}
.qbstp-header-subscribe .col-one-third {
  width: 30%;
}
.qbstp-header-subscribe .col-three-forth, .qbstp-header-subscribe .col-one-third {
  display: table;
  height: 50px;
  float: left;
}
@media screen and (max-width: 768px) {
  .qbstp-header-subscribe .col-three-forth, .qbstp-header-subscribe .col-one-third {
    width: 50%;
  }
}
.qbstp-header-subscribe .form-control {
  background: #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}
.qbstp-header-subscribe .form-group {
  position: relative;
  width: 100%;
  display: table-cell;
  vertical-align: middle;
}
@media screen and (max-width: 480px) {
  .qbstp-header-subscribe .form-group {
    width: 100%;
  }
}
.qbstp-header-subscribe .form-group input::-webkit-input-placeholder {
  color: rgb(76.5, 76.5, 76.5);
}
.qbstp-header-subscribe .form-group input::-moz-input-placeholder {
  color: rgb(76.5, 76.5, 76.5);
}
.qbstp-header-subscribe .form-group input::-ms-input-placeholder {
  color: rgb(76.5, 76.5, 76.5);
}
.qbstp-header-subscribe .form-group input::-o-input-placeholder {
  color: rgb(76.5, 76.5, 76.5);
}
.qbstp-header-subscribe .form-group input::input-placeholder {
  color: rgb(76.5, 76.5, 76.5);
}
.qbstp-header-subscribe #email {
  font-size: 14px;
  width: 100%;
  border: none;
  border-bottom: transparent;
  color: rgb(76.5, 76.5, 76.5) !important;
}
.qbstp-header-subscribe button {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  background: #4aca85;
  color: #fff;
  border: none;
  padding: 10px 20px;
  text-transform: uppercase;
  height: 50px;
  width: 100%;
  display: block;
}

.no-gutters {
  margin: 0 !important;
}

.pagination li {
  margin: 2px;
}
.pagination li a {
  color: #000;
  background: #fff;
  color: #000;
  border: 1px solid rgb(229.5, 229.5, 229.5);
  margin: 2px;
}
.pagination li a:first-child {
  border: 1px solid rgb(229.5, 229.5, 229.5);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  border-radius: 2px;
}
.pagination li a:hover, .pagination li a:focus {
  background: #4aca85;
  color: #fff;
  border: 1px solid #4aca85;
}
@media screen and (max-width: 768px) {
  .pagination li a {
    padding: 7px 15px;
  }
}
.pagination li.active a {
  background: #4aca85;
  border: 1px solid #4aca85;
}
.pagination li.active a:hover, .pagination li.active a:focus {
  background: #4aca85;
  color: #fff;
  border: 1px solid #4aca85;
}

.no-gutters {
  margin: 0 !important;
  padding: 0 !important;
}

.f-blog {
  position: relative;
  padding-left: 80px;
  margin-bottom: 40px;
}
.f-blog .blog-img {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 70px;
  height: 60px;
  float: left;
}
.f-blog .desc h2 {
  font-size: 14px;
  margin-bottom: 5px;
}
.f-blog .desc h2 a {
  color: #fff;
}
.f-blog .desc .admin {
  font-size: 12px;
}

.contact-info-wrap-flex {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  width: 100%;
  margin-bottom: 3em;
}
.contact-info-wrap-flex .con-info {
  width: 100%;
  background: #fff;
  padding: 1.5em;
  background: white;
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .contact-info-wrap-flex .con-info {
    width: 100%;
    margin: 4px !important;
  }
}
.contact-info-wrap-flex p {
  margin-bottom: 0;
  color: #000;
}
.contact-info-wrap-flex p span i {
  color: #4aca85;
}
.contact-info-wrap-flex p a {
  color: #000;
}

.form-control {
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -ms-box-shadow: none;
  -o-box-shadow: none;
  box-shadow: none;
  border: 1px solid rgb(239.7, 239.7, 239.7);
  font-size: 16px !important;
  font-weight: 300;
  background: white;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  border-radius: 0px;
}
.form-control:focus, .form-control:active {
  background: #fff;
  box-shadow: none;
  border: 1px solid rgb(229.5, 229.5, 229.5);
}

input[type=text] {
  height: 50px;
}

.form-group {
  margin-bottom: 30px;
}
@media screen and (max-width: 768px) {
  .form-group #fname {
    margin-bottom: 30px;
  }
}
.form-group label {
  font-weight: 400;
}
.form-group .btn-modify {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-size: 13px;
  padding: 10px 15px;
}

.colorlib-social-icons {
  margin: 0;
  padding: 0;
}
.colorlib-social-icons li {
  margin: 0;
  padding: 0;
  list-style: none;
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.colorlib-social-icons li a {
  display: -moz-inline-stack;
  display: inline-block;
  zoom: 1;
  *display: inline;
  color: #4aca85;
  padding-left: 10px;
  padding-right: 10px;
}
.colorlib-social-icons li a i {
  font-size: 20px;
}

.colorlib-heading {
  margin-bottom: 5em;
}
.colorlib-heading h2 {
  font-size: 50px;
  font-weight: 300;
  margin-bottom: 30px;
  position: relative;
}
@media screen and (max-width: 768px) {
  .colorlib-heading h2 {
    font-size: 30px;
  }
}

#colorlib-footer {
  background: #303133;
  color: rgba(255, 255, 255, 0.6) !important;
  padding-top: 6em;
}
#colorlib-footer .colorlib-widget p {
  font-size: 13px;
}
@media screen and (max-width: 768px) {
  #colorlib-footer .colorlib-widget {
    margin-bottom: 40px;
    width: 100%;
    float: left;
  }
}
#colorlib-footer .colorlib-footer-links {
  padding: 0;
  margin: 0;
}
@media screen and (max-width: 768px) {
  #colorlib-footer .colorlib-footer-links {
    margin-bottom: 30px;
  }
}
#colorlib-footer .colorlib-footer-links li {
  padding: 0;
  margin: 0 0 10px 0;
  list-style: none;
  display: block;
  font-size: 13px;
}
#colorlib-footer .colorlib-footer-links li a {
  color: rgba(255, 255, 255, 0.9);
  text-decoration: none;
}
#colorlib-footer .colorlib-footer-links li a i {
  padding-right: 10px;
  color: #fff;
}
#colorlib-footer .colorlib-social-icons li a {
  padding-left: 10px;
  padding-right: 10px;
}
#colorlib-footer .colorlib-social-icons li a:first-child {
  padding-left: 0;
}
#colorlib-footer h4 {
  margin-bottom: 30px;
  font-size: 12px;
  color: rgb(229.5, 229.5, 229.5);
  text-transform: uppercase;
  letter-spacing: 3px;
  font-weight: 700;
}
#colorlib-footer .copy {
  background: rgb(38.25, 38.25, 38.25);
  padding: 7em 0;
}
#colorlib-footer .copy p {
  margin-bottom: 0;
  color: #fff;
  font-size: 13px;
}
#colorlib-footer .copy p small a {
  color: rgba(255, 255, 255, 0.7);
}

#map {
  width: 100%;
  height: 500px;
  position: relative;
}
@media screen and (max-width: 768px) {
  #map {
    height: 200px;
  }
}

#colorlib-offcanvas {
  position: absolute;
  z-index: 1901;
  width: 270px;
  background: rgb(25.5, 25.5, 25.5);
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  padding: 75px 40px 40px 40px;
  overflow-y: auto;
  display: none;
  -moz-transform: translateX(270px);
  -webkit-transform: translateX(270px);
  -ms-transform: translateX(270px);
  -o-transform: translateX(270px);
  transform: translateX(270px);
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
@media screen and (max-width: 768px) {
  #colorlib-offcanvas {
    display: block;
  }
}
.offcanvas #colorlib-offcanvas {
  -moz-transform: translateX(0px);
  -webkit-transform: translateX(0px);
  -ms-transform: translateX(0px);
  -o-transform: translateX(0px);
  transform: translateX(0px);
}
#colorlib-offcanvas a {
  color: rgba(255, 255, 255, 0.5);
}
#colorlib-offcanvas a:hover {
  color: rgba(255, 255, 255, 0.8);
}
#colorlib-offcanvas ul {
  padding: 0;
  margin: 0;
}
#colorlib-offcanvas ul li {
  padding: 0;
  margin: 0;
  list-style: none;
}
#colorlib-offcanvas ul li > ul {
  padding-left: 20px;
  display: none;
}
#colorlib-offcanvas ul li.offcanvas-has-dropdown > a {
  display: block;
  position: relative;
}
#colorlib-offcanvas ul li.offcanvas-has-dropdown > a:after {
  position: absolute;
  right: 0px;
  font-family: "icomoon";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: "\e0c5";
  font-size: 20px;
  color: rgba(255, 255, 255, 0.2);
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
#colorlib-offcanvas ul li.offcanvas-has-dropdown.active a:after {
  -webkit-transform: rotate(-180deg);
  -moz-transform: rotate(-180deg);
  -ms-transform: rotate(-180deg);
  -o-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
#colorlib-offcanvas ul li.btn-cta {
  margin-top: 5px;
}
#colorlib-offcanvas ul li.btn-cta a {
  border-bottom: 1px solid #4aca85;
  padding-bottom: 5px;
}

.gototop {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.gototop.active {
  opacity: 1;
  visibility: visible;
}
.gototop a {
  width: 50px;
  height: 50px;
  display: table;
  background: black;
  color: #fff;
  text-align: center;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
}
.gototop a i {
  height: 50px;
  display: table-cell;
  vertical-align: middle;
}
.gototop a:hover, .gototop a:active, .gototop a:focus {
  text-decoration: none;
  outline: none;
}

.colorlib-nav-toggle {
  width: 25px;
  height: 25px;
  cursor: pointer;
  text-decoration: none;
  top: 1.2em !important;
}
.colorlib-nav-toggle.active i::before, .colorlib-nav-toggle.active i::after {
  background: #303133;
}
.colorlib-nav-toggle:hover, .colorlib-nav-toggle:focus, .colorlib-nav-toggle:active {
  outline: none;
  border-bottom: none !important;
}
.colorlib-nav-toggle i {
  position: relative;
  display: inline-block;
  width: 25px;
  height: 2px;
  color: #252525;
  font: bold 14px/0.4 Helvetica;
  text-transform: uppercase;
  text-indent: -55px;
  background: #252525;
  transition: all 0.2s ease-out;
}
.colorlib-nav-toggle i::before, .colorlib-nav-toggle i::after {
  content: "";
  width: 25px;
  height: 2px;
  background: #252525;
  position: absolute;
  left: 0;
  transition: all 0.2s ease-out;
}
.colorlib-nav-toggle.colorlib-nav-white > i {
  color: #fff;
  background: #fff;
}
.colorlib-nav-toggle.colorlib-nav-white > i::before, .colorlib-nav-toggle.colorlib-nav-white > i::after {
  background: #fff;
}

.colorlib-nav-toggle i::before {
  top: -7px;
}

.colorlib-nav-toggle i::after {
  bottom: -7px;
}

.colorlib-nav-toggle:hover i::before {
  top: -10px;
}

.colorlib-nav-toggle:hover i::after {
  bottom: -10px;
}

.colorlib-nav-toggle.active i {
  background: transparent;
}

.colorlib-nav-toggle.active i::before {
  top: 0;
  -webkit-transform: rotateZ(45deg);
  -moz-transform: rotateZ(45deg);
  -ms-transform: rotateZ(45deg);
  -o-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  background: #fff;
}

.colorlib-nav-toggle.active i::after {
  bottom: 0;
  -webkit-transform: rotateZ(-45deg);
  -moz-transform: rotateZ(-45deg);
  -ms-transform: rotateZ(-45deg);
  -o-transform: rotateZ(-45deg);
  transform: rotateZ(-45deg);
  background: #fff;
}

.colorlib-nav-toggle {
  position: absolute;
  right: 0px;
  top: 65px;
  z-index: 21;
  padding: 6px 0 0 0;
  display: block;
  margin: 0 auto;
  display: none;
  height: 44px;
  width: 44px;
  z-index: 2001;
  border-bottom: none !important;
}
@media screen and (max-width: 768px) {
  .colorlib-nav-toggle {
    display: block;
  }
}

.btn {
  margin-right: 4px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 400;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  border-radius: 30px;
  -moz-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  transition: all 0.5s ease;
  padding: 8px 20px;
}
.btn.btn-md {
  padding: 8px 20px !important;
}
.btn.btn-lg {
  padding: 18px 36px !important;
}
.btn:hover, .btn:active, .btn:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary {
  background: #4aca85;
  color: #fff;
  border: 1px solid #4aca85;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:active {
  background: rgb(93.7243589744, 207.7756410256, 146.2948717949) !important;
  border-color: rgb(93.7243589744, 207.7756410256, 146.2948717949) !important;
}
.btn-primary.btn-outline {
  background: transparent;
  color: #4aca85;
  border: 1px solid #4aca85;
}
.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
  background: #4aca85;
  color: #fff;
}

.btn-success {
  background: #5cb85c;
  color: #fff;
  border: 1px solid #5cb85c;
}
.btn-success:hover, .btn-success:focus, .btn-success:active {
  background: rgb(76.0064102564, 174.4935897436, 76.0064102564) !important;
  border-color: rgb(76.0064102564, 174.4935897436, 76.0064102564) !important;
}
.btn-success.btn-outline {
  background: transparent;
  color: #5cb85c;
  border: 2px solid #5cb85c;
}
.btn-success.btn-outline:hover, .btn-success.btn-outline:focus, .btn-success.btn-outline:active {
  background: #5cb85c;
  color: #fff;
}

.btn-info {
  background: #5bc0de;
  color: #fff;
  border: 1px solid #5bc0de;
}
.btn-info:hover, .btn-info:focus, .btn-info:active {
  background: rgb(69.7715736041, 183.845177665, 217.7284263959) !important;
  border-color: rgb(69.7715736041, 183.845177665, 217.7284263959) !important;
}
.btn-info.btn-outline {
  background: transparent;
  color: #5bc0de;
  border: 1px solid #5bc0de;
}
.btn-info.btn-outline:hover, .btn-info.btn-outline:focus, .btn-info.btn-outline:active {
  background: #5bc0de;
  color: #fff;
}

.btn-warning {
  background: #f0ad4e;
  color: #fff;
  border: 1px solid #f0ad4e;
}
.btn-warning:hover, .btn-warning:focus, .btn-warning:active {
  background: rgb(238.0078125, 162.109375, 54.4921875) !important;
  border-color: rgb(238.0078125, 162.109375, 54.4921875) !important;
}
.btn-warning.btn-outline {
  background: transparent;
  color: #f0ad4e;
  border: 1px solid #f0ad4e;
}
.btn-warning.btn-outline:hover, .btn-warning.btn-outline:focus, .btn-warning.btn-outline:active {
  background: #f0ad4e;
  color: #fff;
}

.btn-danger {
  background: #d9534f;
  color: #fff;
  border: 1px solid #d9534f;
}
.btn-danger:hover, .btn-danger:focus, .btn-danger:active {
  background: rgb(212.4719626168, 62.5046728972, 58.0280373832) !important;
  border-color: rgb(212.4719626168, 62.5046728972, 58.0280373832) !important;
}
.btn-danger.btn-outline {
  background: transparent;
  color: #d9534f;
  border: 1px solid #d9534f;
}
.btn-danger.btn-outline:hover, .btn-danger.btn-outline:focus, .btn-danger.btn-outline:active {
  background: #d9534f;
  color: #fff;
}

.btn-outline {
  background: none;
  border: 1px solid rgb(127.5, 127.5, 127.5);
  font-size: 16px;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn-outline:hover, .btn-outline:focus, .btn-outline:active {
  box-shadow: none;
}

.btn.with-arrow {
  position: relative;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -webkit-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.btn.with-arrow i {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: 0px;
  top: 50%;
  margin-top: -8px;
  -moz-transition: all 0.2s ease;
  -o-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  -ms-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.btn.with-arrow:hover {
  padding-right: 50px;
}
.btn.with-arrow:hover i {
  color: #fff;
  right: 18px;
  visibility: visible;
  opacity: 1;
}

.row-pb-lg {
  padding-bottom: 7em !important;
}

.row-pb-md {
  padding-bottom: 5em !important;
}

.row-pb-sm {
  padding-bottom: 3em !important;
}

.nopadding {
  padding: 0 !important;
  margin: 0 !important;
}

.colorlib-loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url(../../img/loader.gif) center no-repeat #fff;
}

.js .animate-box {
  opacity: 0;
}